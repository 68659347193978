import React, { useState } from "react";
import Page from "../components/Layout/Page";
import { graphql } from "gatsby";
import { Query } from "..";
import GatsbyImage from "gatsby-image";
import pageColors from "../data/pageColors";
import pageTitles from "../data/pageTitles";
import PageHeader from "../components/Elements/PageHeader";
import ExternalLink from "../components/Elements/ExternalLink";
import ResponsiveIframe from "../components/Elements/ResponsiveIframe";
import SquareImage from "../components/Elements/SquareImage";

const getCategoryName = (fieldValue: string) => {
    const basename = fieldValue.replace("illustrations/", "");
    const categories = {
        maya: "3D",
        digital: "Digital",
        drawings: "Paper",
    };

    return categories[basename] || basename;
};

const CategoryHeader = ({ children }) => (
    <h1
        className={
            "text-white text-3xl md:text-6xl font-sans font-hairline my-5"
        }
    >
        {children}
    </h1>
);

const Category = ({ title, children }) => (
    <article
        className={`bg-${pageColors.illustration}-800 hover:bg-${pageColors.illustration}-900 transition p-3 md:p-10`}
    >
        <CategoryHeader>{title}</CategoryHeader>
        {children}
    </article>
);

const Illustration = ({ data }: { data: Query }) => {
    const [zoomedImage, setZoomedImage] = useState(null);

    return (
        <Page title={pageTitles.illustration}>
            <PageHeader
                color={pageColors.illustration}
                counts={[`${data.allFile.totalCount + 1} media`]}
            >
                I went into illustration mostly around my time at art school, so
                this is a mix of assignments and free illustrations.
                <br />I worked with various mediums, my favorite being ink and
                water as well as some digital painting in Photoshop.
            </PageHeader>
            <div className="flex w-full flex-col">
                {data.allFile.group.map((category) => (
                    <Category title={getCategoryName(category.fieldValue)}>
                        {category.nodes.map((image) => (
                            <ExternalLink
                                className={
                                    "inline-block max-w-full xl:w-1/3 w-full md:w-1/2"
                                }
                                href={image.childImageSharp.original.src}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setZoomedImage(image);
                                }}
                            >
                                <SquareImage
                                    className={
                                        "mr-2 mb-2 hover:opacity-75 cursor-pointer transition"
                                    }
                                    // @ts-ignore
                                    fluid={image.childImageSharp.thumbnail}
                                    style={{ maxHeight: 500 }}
                                    imgStyle={{ objectFit: "cover" }}
                                />
                            </ExternalLink>
                        ))}
                    </Category>
                ))}
                <Category title={"Video"}>
                    <ResponsiveIframe
                        title={"Autopergamene"}
                        src="https://www.youtube.com/embed/t9xFXo3xhPY"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </Category>
                <div
                    role="presentation"
                    className={
                        "fixed h-screen w-screen top-0 left-0 bg-black-75 z-10 flex justify-center items-center transition"
                    }
                    style={{
                        opacity: zoomedImage ? 1 : 0,
                        pointerEvents: zoomedImage ? "auto" : "none",
                    }}
                    onClick={() => setZoomedImage(null)}
                >
                    {zoomedImage && (
                        <GatsbyImage
                            fixed={zoomedImage.childImageSharp.fixed}
                            className={"shadow-2xl max-h-full"}
                            style={{ maxHeight: "90vh" }}
                            imgStyle={{ objectFit: "contain" }}
                        />
                    )}
                </div>
            </div>
        </Page>
    );
};

export const query = graphql`
    {
        allFile(
            filter: { dir: { regex: "/illustration/" } }
            sort: { fields: name }
        ) {
            totalCount
            group(field: relativeDirectory) {
                fieldValue
                nodes {
                    id
                    relativeDirectory
                    name
                    childImageSharp {
                        fixed(quality: 90, width: 1000) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                        original {
                            src
                        }
                        thumbnail: fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

export default Illustration;
